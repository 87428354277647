<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <div class="title mb-3">{{ selectedDoctorInfo.serviceName }}</div>
        <div class="department-name">
          <strong>Клиника:</strong> {{ selectedDoctorInfo.departmentName }}
        </div>
        <div class="doctor-name">
          <strong>Доктор:</strong> {{ selectedDoctorInfo.doctorName }}
        </div>
        <div class="price">
          <strong>Цена: </strong>{{ selectedDoctorInfo.servicePrice }} руб.
        </div>
        <div class="city-district">
          <strong>Район города: </strong>{{ selectedDoctorInfo.cityPart }}
        </div>
      </v-col>
      <v-col>
        <order-form :services="this.orderData" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import * as names from "store/names";
import OrderForm from "components/partials/OrderForm";

export default {
  name: "DoctorCardFast",
  components: { OrderForm },
  data: () => ({
    orderData: [],
  }),
  computed: {
    ...mapGetters({
      selectedDoctorInfo: names.SELECTED_DOCTOR_INFO,
    }),
  },
  mounted() {
    this.collectOrderData();
  },
  methods: {
    ...mapActions({}),
    collectOrderData() {
      this.orderData = {
        departmentName: this.objDepartment.name,

        departmentId: this.objDepartment.departmentId,

        departmentAddress: this.objDepartment.address,

        departmentPhone: this.objDepartment.phones[0].phone,

        serviceName: this.objSelectedDoctor.serviceName,

        doctorName: this.objSelectedDoctor.doctorName,

        doctorServicePrice: this.objSelectedDoctor.servicePrice,

        servicePrice: this.objSelectedDoctor.servicePrice,

        childDoc: false,

      };
    },
  },
};
</script>

<style scoped></style>
